import Link from 'next/link';
import React from 'react';
import Button from '../../Button';
import styles from './styles.module.scss';
import { useRouter } from 'next/router';

const HeroInfo = ({
    authData,
    utilState,
    showConnectWalletPopup,
    hideConnectWalletPopup,
    loginEvent,
    className,
    ...props
}) => {
    const router = useRouter();
    return (
        <div className={`${styles.content} ${className}`}>
            <h1 className={styles.title}>
                <img src="/assets/img/logo/final-logo.png" alt="" /> - The Art
                of NFTs Created by an Artist for all Artists
            </h1>
            <p className={styles.text}>
                {/* Securely buy and sell unique digital collectibles.
        <br />
        Lowest transaction costs across all NFT markets guaranteed. */}
                Create, Buy and Sell Digital Art, Music and Crypto Collectibles
            </p>
            <div className={styles.btns}>
                <Link href="/explore">
                    <a>
                        <Button varient="white" className={styles.btn__clr}>
                            Explore NFTs
                        </Button>
                    </a>
                </Link>
                {!authData?.isAuthenticated ? (
                    <Button
                        varient="white"
                        onClick={() => {
                            showConnectWalletPopup();
                        }}
                        className={` ${styles.btn__clr}`}
                    >
                        Connect Wallet
                    </Button>
                ) : (
                    ''
                )}
            </div>

            {/* <div className={styles.btns}>
            <Button  varient={'primary'}
                onClick={() => {router.push(`/valentines-nft-contest`);}}>Valentine Contest</Button>
      </div> */}
        </div>
    );
};

export default HeroInfo;

/*

     <Row
            className={`${props.className}`}
            >
            <Col 

                className="col-12">

                <div className={styles.content}>
                    <h1 className={styles.title}>Discover, Collect, and Sell extraordinary NFTs</h1>
                    <p className={styles.text}>
                        Securely buy and sell unique digital collectibles.<br />
                        Lowest transaction costs across all NFT markets guaranteed.
                    </p>

                    <div className={styles.btns}>
                        <Button
                            className={styles.btn__clr}
                            >
                            Explore
                        </Button>
                        <Button
                            varient={"primary_outline"}
                            className={`
                            ${styles.btn__clr}
                            `}
                            >
                            Connect Wallet
                        </Button>
                        
                    </div>
                </div>
            </Col>
        </Row>
*/
