import React, {useEffect, useState} from "react";
import HeroInfo from "../../Components/Hero/Info";
import Container from "../../Components/Container";
import { Row, Col } from "react-bootstrap";
import styles from "./styles.module.scss";
import FeaturedNFT from "../../Components/Hero/FeaturedNFT";
import UseAuth from "../../Contexts/Auth/useAuth";
import UseUtil from "../../Contexts/Util/useUtil";
import { getCmsData } from "../../lib/User";
const Hero = (props) => {
  const { authState, loginUser } = UseAuth();
  const { utilState, showConnectWalletPopup, hideConnectWalletPopup } =
    UseUtil();
  const [headerContent, setHeaderContent] = useState(props?.content ? props?.content : {})
  const updateHeaderContent = async () => {
    let faqCall = await getCmsData("header");
    if (faqCall?.status === 200) {
      let faqdata = faqCall.data.data;
      setHeaderContent(faqdata);
    }
  }
  useEffect(() => {
    updateHeaderContent()
  }, [])
  return (
    <div className={styles.home}>
      <Container>
        <Row className={styles.row__extras}>
          <Col
            xxl={5}
            xl={5}
            lg={5}
            md={12}
            sm={12}
            className={` ${styles.info_col}`}
          >
            <HeroInfo
              className={`${styles.info}`}
              authData={authState}
              utilState={utilState}
              showConnectWalletPopup={showConnectWalletPopup}
              hideConnectWalletPopup={hideConnectWalletPopup}
              loginEvent={loginUser}
            />
          </Col>
          <Col
            xxl={7}
            xl={7}
            lg={7}
            md={0}
            sm={0}
            className={`${styles.section_featured_nft}`}
          >
            <FeaturedNFT

              img_src={headerContent?.images}
              alt_tag={"Featured Image"}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
