// import SVG from "react-inlinesvg";
import Image from "next/image";
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import contract from '../../../Configs/Contract';
import useNFT from '../../../Contexts/NFT/useNFT';
// import contract from "../../../Configs/Contract";
// import HeaderNftslider from "../../HeaderNftslider/HeaderNftslider";
import { getHomeBannerData } from '../../../lib/NFT';
import styles from './styles.module.scss';
const FeaturedNFT = ({ img_src, alt_tag, width, height, ...props }) => {
  const { nftState } = useNFT();
  const [homeBannerData, setHomeBannerData] = useState([]);
  const [homeBanner, setHomeBanner] = useState(null);
  const [nftImages, setNftImages] = useState(null);
  //  Audio control
  const hiddenAudioInput = React.useRef(null);
  const [playing, setPlaying] = useState(true);
  const onhandleChange = () => {
    if (hiddenAudioInput.current) {
      if (playing === true) {
        hiddenAudioInput.current.play();
      } else {
        hiddenAudioInput.current.pause();
      }
    }
  };
  const handlePlayOption = () => {
    setPlaying(!playing);
  };
  useEffect(() => {
    playing === true ? onhandleChange() : onhandleChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);
  //  Audio control end
  //  Audio control BackGround
  const hiddenAudioInputbackGround = React.useRef(null);
  const [playingbackGround, setPlayingBackGround] = useState(false);
  const onhandleChangeBackGround = () => {
    if (hiddenAudioInputbackGround.current) {
      if (playingbackGround === true) {
        hiddenAudioInputbackGround.current.play();
      } else {
        hiddenAudioInputbackGround.current.pause();
      }
    }
  };
  const handlePlayOptionBackGround = () => {
    setPlayingBackGround(!playingbackGround);
  };
  useEffect(() => {
    playingbackGround === true
      ? onhandleChangeBackGround()
      : onhandleChangeBackGround();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingbackGround]);
  //  Audio control end

  const homeBannerDataFetch = async () => {
    let result = await getHomeBannerData();
    if (result?.status === 200) {
      let homeBannerResult = result.data.data;
      setHomeBannerData(homeBannerResult);
    }
  };
  useEffect(() => {
    homeBannerDataFetch();
  }, []);

  useEffect(() => {
    let homeBanner = homeBannerData?.filter((value) => value.type === 1);
    if (homeBanner && homeBanner.length > 0) {
      setHomeBanner(homeBanner[0]);
    }
    let nftImages = homeBannerData?.filter((value) => value.type === 2);
    if (nftImages && nftImages.length > 0) {
      let newArray = [];
      for (const value of nftImages) {
        let blockchain = value.nft.blockchain;
        let contractAddress =
          blockchain === 'ethereum'
            ? process.env.NEXT_PUBLIC_CONTRACT_ADDRESS_KEY
            : process.env.NEXT_PUBLIC_POLYGON_CONTRACT_ADDRESS_KEY;
        let linkURL = `${
            value.nft.token_id && value.nft?.for_gallery !== 1
            ? `/token/${contract[contractAddress]}:${value.nft.token_id}`
            : value.nft.token_id && value.nft?.for_gallery === 1
            ? `/gallery/${contract[contractAddress]}:${value.nft.token_id}`
            : '#'
        }`;
        let newObject = {
          nftImage: value.nft.main_asset,
          nftTitle: value.nft.title,
          nftUserName: (value.nft.for_gallery === 1) ? value.nft.artist_name : value.nft.created_by.username,
          linkURL: linkURL,
        };
        newArray.push(newObject);
      }

      setNftImages(newArray);
    }
  }, [homeBannerData]);

  return (
    <>
      <div className={styles.featured_nft}>
        <div className={styles.homebox1} style={{ animationDuration: '6s' }}>
          {nftImages && nftImages.length > 0 && nftImages[0] && (
            <>
              <Link href={`${nftImages[0].linkURL}`}>
                <a>
                  <div className={styles.nft_box}>
                    <Image
                      src={`${process.env.NEXT_PUBLIC_CD_URL}asset/${nftImages[0].nftImage}`}
                      alt={nftImages[0].nftTitle}
                      objectFit="cover"
                      objectPosition="center center"
                      layout="fill"
                    />
                  </div>
                  {/* <img
                    src={`${process.env.NEXT_PUBLIC_CD_URL}asset/${nftImages[0].nftImage}`}
                    alt={nftImages[0].nftTitle}
                    width={'182px'}
                    height={'136px'}
                    className={`img-fluid`}
                  /> */}
                  <h4 className={styles.homeheadings}>
                    {nftImages[0].nftTitle}
                  </h4>
                  <p className={styles.homesubtext}>
                    @{(nftImages[0].nftUserName) ? nftImages[0].nftUserName  : 'Unknown Artist'}
                  </p>
                </a>
              </Link>
            </>
          )}
        </div>

        <div
          className={styles.homebox2}
          style={{ animationDuration: '7s', animationDelay: '1s' }}
        >
          {nftImages && nftImages.length > 0 && nftImages[1] && (
            <>
              <Link href={`${nftImages[1].linkURL}`}>
                <a>
                  <div className={styles.nft_box}>
                    <Image
                      src={`${process.env.NEXT_PUBLIC_CD_URL}asset/${nftImages[1].nftImage}`}
                      alt={`NFT`}
                      objectFit="cover"
                      objectPosition="center center"
                      layout="fill"
                    />
                  </div>
                  {/* <img
                    src={`${process.env.NEXT_PUBLIC_CD_URL}asset/${nftImages[1].nftImage}`}
                    alt={`NFT`}
                    width={'182px'}
                    height={'136px'}
                    className={`img-fluid`}
                  /> */}
                  <h4 className={styles.homeheadings}>
                    {nftImages[1].nftTitle}
                  </h4>
                  <p className={styles.homesubtext}>
                    @{(nftImages[1].nftUserName) ? nftImages[1].nftUserName  : 'Unknown Artist'}
                  </p>
                </a>
              </Link>
            </>
          )}
        </div>

        <div
          className={styles.homebox3}
          style={{ animationDuration: '8s', animationDelay: '2s' }}
        >
          {nftImages && nftImages.length > 0 && nftImages[2] && (
            <>
              <Link href={`${nftImages[2].linkURL}`}>
                <a>
                  <div className={styles.nft_box}>
                    <Image
                      src={`${process.env.NEXT_PUBLIC_CD_URL}asset/${nftImages[2].nftImage}`}
                      alt={`NFT`}
                      objectFit="cover"
                      objectPosition="center center"
                      layout="fill"
                    />
                  </div>
                  {/* <img
                    src={`${process.env.NEXT_PUBLIC_CD_URL}asset/${nftImages[2].nftImage}`}
                    alt={`NFT`}
                    width={'182px'}
                    height={'136px'}
                    className={`img-fluid`}
                  /> */}
                  <h4 className={styles.homeheadings}>
                    {nftImages[2].nftTitle}
                  </h4>
                  <p className={styles.homesubtext}>
                    @{(nftImages[2].nftUserName) ? nftImages[2].nftUserName  : 'Unknown Artist'}
                  </p>
                </a>
              </Link>
            </>
          )}
        </div>
        {homeBanner && (
          <div className={styles.centerimage}>
            <img
              src={`/assets/homepagebanner/${homeBanner?.home_banner_images?.name}`}
              alt={`NFT header`}
              width={'434px'}
              height={'492px'}
              className={`img-fluid`}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FeaturedNFT;
